import './App.css';
import { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { getImage } from './images';

const API_URL = 'https://bykiv3i5h4.execute-api.eu-central-1.amazonaws.com/vault-handleRequest'

const TAGS = {
  [ 'GENERAL' ]: {
    color: '#CA3CFF',
    [ 'FINNISH' ]: 'Yleinen',
    [ 'ENGLISH' ]: 'General',
  },
  [ 'SOCIAL_MEDIA' ]: {
    color: '#0094C6',
    [ 'FINNISH' ]: 'Sosiaalinen Media',
    [ 'ENGLISH' ]: 'Social Media',
  },
  [ 'DATING' ]: {
    color: '#D87CAC',
    [ 'FINNISH' ]: 'Parisuhde',
    [ 'ENGLISH' ]: 'Dating',
  },
  [ 'ESPORTS' ]: {
    color: '#3D52D5',
    [ 'FINNISH' ]: 'Esports',
    [ 'ENGLISH' ]: 'Esports',
  },
  [ 'ENTREPRENEURSHIP' ]: {
    color: 'rgba(5,0,255,1)',
    [ 'FINNISH' ]: 'Yrittäjyys',
    [ 'ENGLISH' ]: 'Entrepreneurship',
  },
  [ 'YOUTUBE' ]: {
    color: 'rgba(255,0,255,1)',
    [ 'FINNISH' ]: 'Youtube',
    [ 'ENGLISH' ]: 'Youtube',
  },
  [ 'GAMING' ]: {
    color: '#3D52D5',
    [ 'FINNISH' ]: 'Videopelit',
    [ 'ENGLISH' ]: 'Video games',
  },
  [ 'FINANCE' ]: {
    color: 'rgba(255,0,0,1)',
    [ 'FINNISH' ]: 'Talous',
    [ 'ENGLISH' ]: 'Finance',
  },
  [ 'MOVIES' ]: {
    color: 'rgba(255,250,0,1)',
    [ 'FINNISH' ]: 'Elokuvat',
    [ 'ENGLISH' ]: 'Movies',
  },
  [ 'HEALTHCARE' ]: {
    color: 'rgba(5,250,250,1)',
    [ 'FINNISH' ]: 'Terveys',
    [ 'ENGLISH' ]: 'Health',
  },
  [ 'TRAVEL' ]: {
    color: '#00B295',
    [ 'FINNISH' ]: 'Matkustus',
    [ 'ENGLISH' ]: 'Travel',
  },
  [ 'LITERATURY' ]: {
    color: 'rgba(255,0,250,1)',
    [ 'FINNISH' ]: 'Kirjat',
    [ 'ENGLISH' ]: 'Books',
  },
  [ 'LITERATURE' ]: {
    color: 'rgba(255,0,250,1)',
    [ 'FINNISH' ]: 'Kirjat',
    [ 'ENGLISH' ]: 'Books',
  },
  [ 'BEAUTY' ]: {
    color: '#D6155C',
    [ 'FINNISH' ]: 'Kauneus',
    [ 'ENGLISH' ]: 'Beauty',
  },
  [ 'CAREER' ]: {
    color: 'rgba(255,0,250,1)',
    [ 'FINNISH' ]: 'Ura',
    [ 'ENGLISH' ]: 'Career',
  },
  [ 'FAMILY' ]: {
    color: 'rgba(255,0,250,1)',
    [ 'FINNISH' ]: 'Perhe',
    [ 'ENGLISH' ]: 'Family',
  },
  [ 'VIDEOGAMES' ]: {
    color: '#3D52D5', 
    [ 'FINNISH' ]: 'Pelaaminen',
    [ 'ENGLISH' ]: 'Video games',
  },
  [ 'TECH' ]: {
    color: '#51CB20',
    [ 'FINNISH' ]: 'Tekniikka',
    [ 'ENGLISH' ]: 'Tech',
  },
  [ 'FITNESS' ]: {
    color: '#EDB230',
    [ 'FINNISH' ]: 'Fitness',
    [ 'ENGLISH' ]: 'Fitness',
  },
  [ 'HEALTH' ]: {
    color: '#FF6F59',
    [ 'FINNISH' ]: 'Terveys',
    [ 'ENGLISH' ]: 'Health',
  },
  [ 'HOME' ]: {
    color: '#B388EB',
    [ 'FINNISH' ]: 'Etusivu',
    [ 'ENGLISH' ]: 'Home',
  },
  [ 'ENTERTAINMENT' ]: {
    color: '#FE5D26',
    [ 'FINNISH' ]: 'Viihde',
    [ 'ENGLISH' ]: 'Entertainment',
  },
  [ 'MONEY' ]: {
    color: '#F1D302',
    [ 'FINNISH' ]: 'Talous',
    [ 'ENGLISH' ]: 'Finances',
  },
}

const translateTags = (tag, language) => {
  const TAG = TAGS[tag]
  return TAG && TAG[language] ? TAG[language] : tag
}

const translateTagColor = (tag) => {
  const TAG = TAGS[tag]
  return TAG ? TAG.color : 'white'
}

const translateLanguage = (subdomain) => {
  if (subdomain === 'eng') {
    return 'ENGLISH'
  }
  return 'FINNISH'
}

const HEADER = [
  {
    id: 'HOME',
    title: 'HOME',
    img: 'HOME_ICON',
    link: '/'
  },
  {
    id: 'DATING',
    title: 'DATING',
    img: 'DATING_ICON',
    link: '/?filter=DATING'
  },  
  {
    id: 'TRAVEL',
    title: 'TRAVEL',
    img: 'TRAVEL_ICON',
    link: '/?filter=TRAVEL'
  },
  {
    id: 'GAMING',
    title: 'GAMING',
    img: 'GAMING_ICON',
    link: '/?filter=GAMING'
  },
  {
    id: 'SOCIAL_MEDIA',
    title: 'SOCIAL_MEDIA',
    img: 'SOCIAL_MEDIA_ICON',
    link: '/?filter=SOCIAL_MEDIA'
  },
  {
    id: 'MONEY',
    title: 'MONEY',
    img: 'MONEY_ICON',
    link: '/?filter=MONEY'
  },
  {
    id: 'TECH',
    title: 'TECH',
    img: 'TECH_ICON',
    link: '/?filter=TECH'
  },
  {
    id: 'FITNESS',
    title: 'FITNESS',
    img: 'FITNESS_ICON',
    link: '/?filter=FITNESS'
  },
  {
    id: 'HEALTH',
    title: 'HEALTH',
    img: 'HEALTH_ICON',
    link: '/?filter=HEALTH'
  },  
  {
    id: 'BEAUTY',
    title: 'BEAUTY',
    img: 'BEAUTY_ICON',
    link: '/?filter=BEAUTY'
  },
  {
    id: 'ENTERTAINMENT',
    title: 'ENTERTAINMENT',
    img: 'ENTERTAINMENT_ICON',
    link: '/?filter=ENTERTAINMENT'
  },
]

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function App() {
  const [ data, setData ] = useState({
    title: '',
    content: '',
    slug: ''
  })
  const [ links, setLinks ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)  
  const isStashVault = window.location.hostname.includes('stash-vault')
  const hasSubdomain = window.location.hostname.split('.').length > 1
  const languageName = translateLanguage(window.location.hostname.split('.')[0])
  const [ activeLanguage, setActiveLanguage ] = useState(isStashVault ? 'ENGLISH' : hasSubdomain ? languageName : 'FINNISH')
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const filter = urlParams.get('filter')
  const [ activeFilter, setActiveFilter ] = useState(filter)

  useEffect(() => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    // Get content by slug
    const newSlug = window.location.pathname    

    if (newSlug === '/' || !newSlug) {
      axios({
        method: 'POST',
        url: API_URL,
        data: {
          slug: newSlug,
          isGet: true,
          activeFilter: activeFilter,
          activeLanguage: activeLanguage === 'ENGLISH' ? '' : activeLanguage
        }
      }).then(response => {
        const responseData = JSON.parse(response.data)

        // set the title
      document.title = 'Moska | ' + (activeFilter ? translateTags(activeFilter, activeLanguage) : translateTags('HOME', activeLanguage))

      // Some snippets for the different categories:
      document.querySelector('meta[name="description"]').setAttribute('content', (activeLanguage === 'FINNISH' ? 'Löydä tietoa eri aiheista.' : 'Find information about different topics.'));

        setData({
          slug: '/',
          title: 'Moska',
          content: ''
        })
        // Get sitemap
        //console.log(responseData.items.filter(_ => _.language === 'FINNISH').map(_ => 'https://moska.fi/' + _.slug).join(`\n`))
        
        setLinks([ ...shuffle(responseData.items.filter(_ => _.language === activeLanguage)) ])
        setIsLoading(false)
      })      
      return
    }
    axios({
      method: 'POST',
      url: API_URL,
      data: {
        slug: newSlug,
      }
    }).then(response => {
      const newData = response && response.data && JSON.parse(response.data)
      if (!newData) {
        setIsLoading(false)
        return
      }

      // set the title
      document.title = newData.title

      // Some snippets for the different categories:
      document.querySelector('meta[name="description"]').setAttribute('content', newData.content.slice(0, 150));

      setData({
        ...data,
        ...newData
      })
      setIsLoading(false)

      // Get links
      axios({
        method: 'POST',
        url: API_URL,
        data: {
          slug: newSlug,
          isGet: true,
          activeFilter: activeFilter,
          activeLanguage: activeLanguage === 'ENGLISH' ? '' : activeLanguage
        }
      }).then(response => {
        const responseData = JSON.parse(response.data)
        setLinks([ ...shuffle(responseData.items.filter(_ => _.language === activeLanguage)) ])
      })   

    })      
  }, [])

  const currentDomain = window.location.hostname
  const currentSlug = window.location.pathname
  const upcomingLang = activeLanguage === 'FINNISH' ? 'eng' : ''
  const isRoot = currentSlug === '/' || !currentSlug    

  const shownLinks = [ ...links.slice(0,1), ...(isRoot ? [{ type: 'MOST_READ' }] : []), ...links.slice(1), ]
  const highlights = [ ...shownLinks.slice(15,20) ]  
  //const dateNow = new Date()

  /*
  const [ scrollTop, setScrollTop ] = useState(0)  
  useEffect(() => {
    const handleScroll = event => {
      setScrollTop(window.scrollY)
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  */

  const headerScrollRef = useRef()
  const handleScroll = useCallback(() => {
    if (!(headerScrollRef && headerScrollRef.current)) {
      return 
    }
    localStorage.setItem('scrollX', headerScrollRef.current.scrollLeft)
  }, [])
  
  useEffect(() => {
    if (!(headerScrollRef && headerScrollRef.current)) {
      return 
    }
    const element = headerScrollRef.current
    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [])

  useEffect(() => {
    if (!(headerScrollRef && headerScrollRef.current)) {
      return 
    }
    const headerScrollPosition = localStorage.getItem('scrollX') || 0; 
    headerScrollRef.current.scroll({ top: 0, left: headerScrollPosition, behaviour: 'smooth' })
  }, [])

  return (
    <div className="App">
      <div className="App-header">
        {/* HEADER */}
        <div style={{ backgroundColor: 'rgba(0,0,0,1)', width: '100vw' }}>
          <div style={{ width: '100%' }}>
            <div style={{ padding: 5, paddingTop: 8, fontWeight: '300', fontSize: 11, backgroundColor: 'rgba(10,13,17,1)', paddingLeft: 15, paddingRight: 15, display: 'flex', alignItems: 'center' }}>
              <div style={{ position: 'absolute', pointerEvents: 'none', zIndex: 0, width: '90vw', overflow: 'hidden' }}>
                <p style={{ textAlign: 'left', fontWeight: '700', fontSize: 26, color: 'rgba(255,255,255,1)', width: '500vw' }}>モスカ <span className="header-decor" style={{ color: 'rgba(255,255,255,0.08)', fontSize: 22 }}>x MOSKA </span><span className="header-decor" style={{ color: 'rgba(255,255,255,0.08)', fontSize: 20 }}>x モスカ </span><span className="header-decor" style={{ color: 'rgba(255,255,255,0.035)', fontSize: 24 }}>x MOSKA </span><span className="header-decor" style={{ color: 'rgba(255,255,255,0.035)', fontSize: 22 }}>x モスカ </span><span className="header-decor" style={{ color: 'rgba(255,255,255,0.02)', fontSize: 24 }}>x MOSKA </span><span className="header-decor" style={{ color: 'rgba(255,255,255,0.02)', fontSize: 24 }}>x モスカ </span><span className="header-decor" style={{ color: 'rgba(255,255,255,0.01)', fontSize: 24 }}>x MOSKA </span><span className="header-decor" style={{ color: 'rgba(255,255,255,0.015)', fontSize: 22 }}>x モスカ </span><span className="header-decor" style={{ color: 'rgba(255,255,255,0.008)', fontSize: 24 }}>x MOSKA </span><span className="header-decor" style={{ color: 'rgba(255,255,255,0.008)', fontSize: 24 }}>x モスカ </span></p>
              </div>
              <a href={'/'} style={{ textDecoration: 'none' }}>
                <p style={{ textAlign: 'left', fontWeight: '700', fontSize: 26, color: 'rgba(255,255,255,1)' }}>モスカ</p>
              </a>
              {/*<p style={{ flex: 1, paddingRight: 13, fontWeight: '400', fontSize: 12, color: 'rgba(255,255,255,0.3)' }}>{ (activeLanguage === 'FINNISH' ? 'Tänään' : 'Today') }: { dateNow.getDate() + '.' + (dateNow.getMonth()+1) + '.' + dateNow.getFullYear() }</p>*/}
              <div style={{ flex: 1 }} />
              { !isStashVault && (
              <a style={{ minWidth: 40 }} href={ 'https://' + (upcomingLang ? upcomingLang + '.' : '') + currentDomain.split('.').reverse()[1] + '.' + currentDomain.split('.').reverse()[0] + currentSlug }>
                <div>
                  <div style={{ position: 'absolute', zIndex: -1 }}>
                    <p style={{ fontSize: 8 }}>flag</p>
                  </div>
                  <img alt={'FLAG'} src={getImage(activeLanguage === 'FINNISH' ? 'FINNISH' : 'ENGLISH')} style={{ height: 35, width: 40, objectFit: 'cover' }} />                                    
                </div>
              </a> )}
            </div>
            <div className="header-scroll" ref={headerScrollRef} style={{ width: '100%', fontSize: 10, fontWeight: '400', paddingTop: 0, overflow: 'auto', borderBottom: '1px solid rgba(255,255,255,0.08)', borderTop: '1px solid rgba(255,255,255,0.08)' }}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '240vw', maxWidth: 1500 }}>
                { HEADER.map(headerItem => (
                   <a key={headerItem.id} className="header-item" style={{ flex: 1, color: 'rgba(255,1,1,1)', textDecoration: 'none', paddingTop: 10, }} href={headerItem.link}>
                    <div style={{ color: 'rgba(255,255,255,1)' }}>
                      <img alt={headerItem.img} src={getImage(headerItem.img)} style={{ height: 19, width: 19, paddingTop: 5, paddingBottom: 2 }} />
                      <p className="header-item-text" style={{ padding: 7, paddingTop: 0, paddingBottom: 10 }}>{ translateTags(headerItem.title, activeLanguage) }</p>
                      { ((headerItem.id === 'HOME' && !activeFilter) || (activeFilter === headerItem.title)) && (
                      <div style={{ height: 2, width: '100%', backgroundColor: 'red' }}></div> )}
                    </div>
                  </a>
                )) }
              </div>
            </div>
          </div>
        </div>

        { !isLoading && (
        <div className="page-contents" style={{ width: '100vw' }}>                            

          {/* ARTICLE PAGE */}
          { !isRoot && (
          <div style={{ paddingTop: 25, paddingBottom: 25, maxWidth: '550px', margin: 'auto' }}>
            <h1 style={{ paddingLeft: 25, paddingRight: 25, margin: 0, marginBottom: 0, paddingTop: 5, fontSize: 28, fontWeight: '700', }}>{ data.title }</h1>
            { (data.tags && data.tags[0]) && (
            <p style={{ paddingTop: 5, paddingBottom: 15, paddingLeft: 25, paddingRight: 25, textAlign: 'left', fontSize: 16, fontWeight: '600' }}>            
              <span style={{ color: translateTagColor(data.tags[0]) }}>
                { translateTags(data.tags[0], activeLanguage) }
              </span>
            </p> )}  
            { !(data.tags && data.tags[0]) && (
            <p style={{ paddingLeft: 5, paddingTop: 15, paddingBottom: 15, textAlign: 'right', fontSize: 20, fontWeight: '400' }}>            
              <span style={{ color: translateTagColor('GENERAL') }}>
                { translateTags('GENERAL', activeLanguage) }
              </span>
            </p> )}   
            { !isRoot && data.img && (            
            <img alt={data.img} src={getImage(data.img)} style={{ width: '100%', paddingTop: 20 }} /> )}
            <div className="content" style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
              <p style={{ fontSize: 14, fontWeight: '400', color: 'rgba(255,255,255,0.75)' }}>{ data.content }</p>
            </div>   
          </div>  )}


          {/* FRONT PAGE */}
          { true && (
          <div className="content articles-container" style={{ paddingTop: 10 }}>
            { shownLinks.filter(_ => !activeFilter || (_.tags && _.tags.includes(activeFilter))).slice(0, isRoot ? 33 : 6).map((link, linkIndex) => {
              if (link.type === 'MOST_READ') {  
                return (
                  <div key={'MOST_READ-' + linkIndex} className="article-item" style={{ backgroundColor: 'rgba(255,255,255,0.0)', border: '0px solid rgba(255,255,255,0.005)' }}>
                    <div style={{ padding: 20 }}>
                      <p style={{ fontWeight: '600', fontSize: 20 }}>{ activeLanguage === 'FINNISH' ? 'Suosituimmat' : 'Most popular' }</p>
                      <div style={{ height: 2, width: '100%', borderRadius: 0, overflow: 'hidden', backgroundColor: 'rgba(255,0,0,1)', marginTop: 15, marginBottom: 10 }} />
                      <div>
                        { highlights.map((highlight, highlightIndex) => {                          
                          return (
                            <a key={'H-' + highlightIndex} style={{ paddingTop: 10, textDecoration: 'none' }} href={'/' + highlight.slug}>
                              <div className="article-highlight">
                                <p style={{ fontSize: 14, color: 'rgba(255,255,255,0.85)', fontWeight: '600', paddingTop: 10 }}>
                                  { !(highlight.tags && highlight.tags[0]) && (
                                  <span style={{ color: translateTagColor('GENERAL') }}>{ translateTags('GENERAL', activeLanguage) + ' - ' }</span> )}
                                  { (highlight.tags && highlight.tags[0]) && (
                                  <span style={{ color: translateTagColor(highlight.tags[0]) }}>{ translateTags(highlight.tags[0], activeLanguage) + ' - ' }</span> )}
                                  { highlight.title }
                                </p>
                                <div style={{ height: 0, width: '100%', backgroundColor: 'rgba(255,255,255,0.1)', marginTop: 10 }} />
                              </div>
                            </a>
                          )
                        }) }
                      </div>
                    </div>
                  </div>
                )
              }
              return (
              <div key={activeFilter + ' ' + link.slug} className="article-item article-card" style={{ backgroundColor: 'rgba(255,255,255,0.03)', border: '1px solid rgba(255,255,255,0.006)' }}>
                <a style={{ color: 'rgba(220,220,220,1)', textDecoration: 'none', fontWeight: '600' }} href={'/' + link.slug}>
                  <div style={{ padding: 15 }}>
                    { link.img && (
                    <img alt={link.img} src={getImage(link.img)} style={{ width: '100%', height: '100%', maxHeight: '600px', paddingBottom: 13 }} /> )}
                    <p style={{ paddingLeft: 5, fontSize: 18 }}>
                      { !(link.tags && link.tags[0]) && (
                      <span style={{ color: translateTagColor('GENERAL') }}>{ translateTags('GENERAL', activeLanguage) + ' - ' }</span> )}
                      { (link.tags && link.tags[0]) && (
                      <span style={{ color: translateTagColor(link.tags[0]) }}>{ translateTags(link.tags[0], activeLanguage) + ' - ' }</span> )}
                      { link.title }
                    </p>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10 }}>                    
                      <p style={{ flex: 1, paddingLeft: 5, fontSize: 13, fontWeight: '400', color: 'rgba(255,255,255,0.1)' }}>
                          { (link.tags && link.tags.filter((_, tagIndex) => true).map((_, tagIndex) => (<span key={'T-' + tagIndex}>{ translateTags(_, activeLanguage) + ( link.tags.length > (tagIndex+1) ? ' | ' : '' ) }</span>))) }
                          { (link.tags && link.tags.filter((_, tagIndex) => true).length === 0) && (<span>{ translateTags('GENERAL', activeLanguage) }</span>) }
                      </p>
                      <p style={{ paddingLeft: 5, fontSize: 13, fontWeight: '400', color: 'rgba(255,255,255,0.1)' }}>
                        { activeLanguage === 'FINNISH' ? 'vuodelta' : 'written' } 2023
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            )}) }
          </div> )}  
        </div> )}
      </div>

      { !isLoading && (
      <div className="page-contents" style={{ backgroundColor: 'black', color: 'rgba(255,255,255,0.5)', fontSize: 14, borderTop: '1px solid rgba(255,255,255,0.06)', borderBottom: '1px solid rgba(255,255,255,0.06)' }}>
        <div style={{ display: 'flex', flexDirection: 'row', padding: 20 }}>
          <div style={{ flex: 1 }}>
            <a style={{ textDecoration: 'none', color: 'rgba(255,255,255,0.5)', }} href={'https://docs.google.com/document/d/e/2PACX-1vQDYQVjOpShwHxnkSC0h8NeDWyEzkiRn3XbrUrhc23Ndq-T6VgH-yrNKfnC1WaNeNA6SStoMPl_hmhy/pub'}>
              <p>{ activeLanguage === 'FINNISH' ? 'Tietosuojaseloste' : 'Privacy Policy' }</p>
            </a>
          </div>
        </div>
      </div> )}
    </div>
  );
}

export default App;
