const IMAGES = {
  [ 'socialmedia1' ]: require('./some1.webp'),
  [ 'socialmedia2' ]: require('./some2.webp'),
  [ 'socialmedia3' ]: require('./some2.webp'),
  [ 'socialmedia4' ]: require('./some2.webp'),
  [ 'socialmedia5' ]: require('./some2.webp'),
  [ 'socialmedia6' ]: require('./some2.webp'),
  [ 'socialmedia7' ]: require('./some2.webp'),
  [ 'socialmedia8' ]: require('./some2.webp'),
  [ 'socialmedia9' ]: require('./some2.webp'),
  [ 'dating1' ]: require('./relationship1.webp'),
  [ 'dating2' ]: require('./relationship2.webp'),
  [ 'dating3' ]: require('./relationship2.webp'),
  [ 'dating4' ]: require('./relationship2.webp'),
  [ 'dating5' ]: require('./relationship2.webp'),
  [ 'dating6' ]: require('./relationship2.webp'),
  [ 'dating7' ]: require('./relationship2.webp'),
  [ 'dating8' ]: require('./relationship2.webp'),
  [ 'dating9' ]: require('./relationship2.webp'),
  [ 'entrepreneurship1' ]: require('./entrepreneurship.webp'),
  [ 'esports1' ]: require('./esports3.webp'),
  [ 'gaming1' ]: require('./esports3.webp'),
  [ 'gaming2' ]: require('./esports3.webp'),
  [ 'gaming3' ]: require('./esports3.webp'),
  [ 'gaming4' ]: require('./esports3.webp'),
  [ 'gaming5' ]: require('./esports3.webp'),
  [ 'gaming6' ]: require('./esports3.webp'),
  [ 'gaming7' ]: require('./esports3.webp'),
  [ 'gaming8' ]: require('./esports3.webp'),
  [ 'gaming9' ]: require('./esports3.webp'),
  [ 'health1' ]: require('./health1.webp'),
  [ 'health2' ]: require('./health1.webp'),
  [ 'health3' ]: require('./health1.webp'),
  [ 'health4' ]: require('./health1.webp'),
  [ 'health5' ]: require('./health1.webp'),
  [ 'health6' ]: require('./health1.webp'),
  [ 'health7' ]: require('./health1.webp'),
  [ 'health8' ]: require('./health1.webp'),
  [ 'health9' ]: require('./health1.webp'),
  [ 'money1' ]: require('./money1.webp'),
  [ 'money2' ]: require('./money1.webp'),
  [ 'money3' ]: require('./money1.webp'),
  [ 'money4' ]: require('./money1.webp'),
  [ 'money5' ]: require('./money1.webp'),
  [ 'money6' ]: require('./money1.webp'),
  [ 'money7' ]: require('./money1.webp'),
  [ 'money8' ]: require('./money1.webp'),
  [ 'money9' ]: require('./money1.webp'),
  [ 'books1' ]: require('./books1.webp'),
  [ 'books2' ]: require('./books1.webp'),
  [ 'books3' ]: require('./books1.webp'),
  [ 'books4' ]: require('./books1.webp'),
  [ 'books5' ]: require('./books1.webp'),
  [ 'books6' ]: require('./books1.webp'),
  [ 'books7' ]: require('./books1.webp'),
  [ 'books8' ]: require('./books1.webp'),
  [ 'books9' ]: require('./books1.webp'),
  [ 'tech1' ]: require('./tech1.webp'),
  [ 'tech2' ]: require('./tech1.webp'),
  [ 'tech3' ]: require('./tech1.webp'),
  [ 'tech4' ]: require('./tech1.webp'),
  [ 'tech5' ]: require('./tech1.webp'),
  [ 'tech6' ]: require('./tech1.webp'),
  [ 'tech7' ]: require('./tech1.webp'),
  [ 'tech8' ]: require('./tech1.webp'),
  [ 'tech9' ]: require('./tech1.webp'),
  [ 'travel1' ]: require('./travel1.webp'),
  [ 'travel2' ]: require('./travel1.webp'),
  [ 'travel3' ]: require('./travel1.webp'),
  [ 'travel4' ]: require('./travel1.webp'),
  [ 'travel5' ]: require('./travel1.webp'),
  [ 'travel6' ]: require('./travel1.webp'),
  [ 'travel7' ]: require('./travel1.webp'),
  [ 'travel8' ]: require('./travel1.webp'),
  [ 'travel9' ]: require('./travel1.webp'),
  [ 'career2' ]: require('./entrepreneurship.webp'),
  [ 'career3' ]: require('./entrepreneurship.webp'),
  [ 'career4' ]: require('./entrepreneurship.webp'),
  [ 'career6' ]: require('./entrepreneurship.webp'),
  [ 'career7' ]: require('./entrepreneurship.webp'),
  [ 'career8' ]: require('./entrepreneurship.webp'),
  [ 'career9' ]: require('./entrepreneurship.webp'),
  [ 'fitness1' ]: require('./health1.webp'),
  [ 'fitness2' ]: require('./health1.webp'),
  [ 'fitness3' ]: require('./health1.webp'),
  [ 'fitness4' ]: require('./health1.webp'),
  [ 'fitness5' ]: require('./health1.webp'),
  [ 'fitness6' ]: require('./health1.webp'),
  [ 'fitness7' ]: require('./health1.webp'),
  [ 'fitness8' ]: require('./health1.webp'),
  [ 'fitness9' ]: require('./health1.webp'),

  [ 'mark1' ]: require('./mark1.webp'),
  [ 'mark2' ]: require('./mark2.webp'),
  [ 'minec1' ]: require('./minec1.webp'),
  [ 'minec2' ]: require('./minec2.webp'),
  [ 'minec3' ]: require('./minec3.webp'),
  [ 'pewds1' ]: require('./pewds1.webp'),
  [ 'plane1' ]: require('./plane1.webp'),
  [ 'plane2' ]: require('./plane2.webp'),
  [ 'poki1' ]: require('./poki1.webp'),
  [ 'poki2' ]: require('./poki2.webp'),
  [ 'poki3' ]: require('./poki3.webp'),
  [ 'tinder1' ]: require('./tinder1.webp'),
  [ 'tinder2' ]: require('./tinder2.webp'),
  [ 'tinder3' ]: require('./tinder3.webp'),
  [ 'tips1' ]: require('./tips1.webp'),
  [ 'youtuber1' ]: require('./youtuber1.webp'),
  [ 'youtuber2' ]: require('./youtuber2.webp'),
  [ 'youtuber3' ]: require('./youtuber3.webp'),
  [ 'youtubers4' ]: require('./youtubers4.webp'),
  [ 'youtubers5' ]: require('./youtubers5.webp'),
  [ 'youtubers6' ]: require('./youtubers6.webp'),
  [ 'kids1' ]: require('./kids1.webp'),
  [ 'kids3' ]: require('./kids3.webp'),
  [ 'kids4' ]: require('./kids4.webp'),
  [ 'kids5' ]: require('./kids5.webp'),
  [ 'job1' ]: require('./job1.webp'),
  [ 'job2' ]: require('./job2.webp'),
  [ 'gym1' ]: require('./gym1.webp'),
  [ 'gym2' ]: require('./gym2.webp'),
  [ 'gym3' ]: require('./gym3.webp'),
  [ 'gamer1' ]: require('./gamer1.webp'),
  [ 'gamer2' ]: require('./gamer2.webp'),
  [ 'gamer3' ]: require('./gamer3.webp'),
  [ 'gamer4' ]: require('./gamer4.webp'),
  [ 'fortnite1' ]: require('./fortnite1.webp'),
  [ 'date1' ]: require('./date1.webp'),
  [ 'date2' ]: require('./date2.webp'),
  [ 'crush1' ]: require('./crush.webp'),
  [ 'career5' ]: require('./career5.webp'),
  [ 'cafe1' ]: require('./cafe1.webp'),
  [ 'cafe2' ]: require('./cafe2.webp'),
  [ 'cafe3' ]: require('./cafe3.webp'),

  [ 'acro1' ]: require('./acro1.webp'),
  [ 'acro2' ]: require('./acro2.webp'),
  [ 'asia1' ]: require('./asia1.webp'),
  [ 'asia2' ]: require('./asia2.webp'),
  [ 'asia3' ]: require('./asia3.webp'),
  [ 'asia4' ]: require('./asia4.webp'),
  [ 'beach1' ]: require('./beach1.webp'),
  [ 'beach2' ]: require('./beach2.webp'),
  [ 'business1' ]: require('./business1.webp'),
  [ 'business2' ]: require('./business2.webp'),
  [ 'cinema1' ]: require('./cinema1.webp'),
  [ 'crush2' ]: require('./crush2.webp'),
  [ 'crush3' ]: require('./crush3.webp'),
  [ 'developer1' ]: require('./developer1.webp'),
  [ 'dorian1' ]: require('./dorian1.webp'),
  [ 'eceleb1' ]: require('./eceleb1.webp'),
  [ 'eceleb2' ]: require('./eceleb2.webp'),
  [ 'europe1' ]: require('./europe1.webp'),
  [ 'food1' ]: require('./food1.webp'),
  [ 'food2' ]: require('./food2.webp'),
  [ 'food3' ]: require('./food3.webp'),
  [ 'food4' ]: require('./food4.webp'),
  [ 'grape1' ]: require('./grape1.webp'),
  [ 'house1' ]: require('./house1.webp'),
  [ 'inception1' ]: require('./inception1.webp'),
  [ 'ivan1' ]: require('./ivan1.webp'),
  [ 'lawyers1' ]: require('./lawyers1.webp'),
  [ 'medi1' ]: require('./medi1.webp'),
  [ 'medi2' ]: require('./medi2.webp'),
  [ 'mocking1' ]: require('./mocking1.webp'),
  [ 'model1' ]: require('./model1.webp'),
  [ 'model2' ]: require('./model2.webp'),
  [ 'model3' ]: require('./model3.webp'),
  [ 'money4' ]: require('./money4.webp'),
  [ 'oldman1' ]: require('./oldman1.webp'),
  [ 'park1' ]: require('./park1.webp'),
  [ 'park2' ]: require('./park2.webp'),
  [ 'park3' ]: require('./park3.webp'),
  [ 'pride1' ]: require('./pride1.webp'),
  [ 'roadtrip1' ]: require('./roadtrip1.webp'),
  [ 'roadtrip2' ]: require('./roadtrip2.webp'),
  [ 'roblox1' ]: require('./roblox1.webp'),
  [ 'rye1' ]: require('./rye1.webp'),
  [ 'skin1' ]: require('./skin1.webp'),
  [ 'skin2' ]: require('./skin2.webp'),
  [ 'sleep1' ]: require('./sleep1.webp'),
  [ 'stretches1' ]: require('./streches1.webp'),
  [ 'tech6' ]: require('./tech6.webp'),
  [ 'thinking1' ]: require('./thinking1.webp'),
  [ 'tinder4' ]: require('./tinder4.webp'),
  [ 'tinder5' ]: require('./tinder5.webp'),
  [ 'tinder6' ]: require('./tinder6.webp'),
  [ 'tinder7' ]: require('./tinder7.webp'),
  [ 'tinder8' ]: require('./tinder8.webp'),
  [ 'tips2' ]: require('./tips2.webp'),
  [ 'turmeric1' ]: require('./turmeric1.webp'),
  [ 'weight1' ]: require('./weight1.webp'),
  [ 'world1' ]: require('./world1.webp'),
  [ 'world2' ]: require('./world2.webp'),
  [ 'yoga1' ]: require('./yoga1.webp'),


  [ 'HOME_ICON' ]: require('./home_icon.png'),
  [ 'DATING_ICON' ]: require('./chat.png'),
  [ 'GAMING_ICON' ]: require('./games.png'),
  [ 'SOCIAL_MEDIA_ICON' ]: require('./network.png'),
  [ 'HEALTH_ICON' ]: require('./lifestyle.png'),
  [ 'MONEY_ICON' ]: require('./wallet-filled-money-tool.png'),
  [ 'TRAVEL_ICON' ]: require('./plane.png'),
  [ 'TECH_ICON' ]: require('./technology.png'),
  [ 'FITNESS_ICON' ]: require('./gym.png'),
  [ 'BEAUTY_ICON' ]: require('./flower.png'),
  [ 'ENTERTAINMENT_ICON' ]: require('./film.png'),
  [ 'FINNISH' ]: require('./finland.png'),
  [ 'ENGLISH' ]: require('./united-kingdom.png'),
}

export const getImage = (id) => {
  return IMAGES[id] || require('./history1.webp')
}